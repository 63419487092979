
@include MQ(L) {
  .make-opacity {
    background-color: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 12;
  }
}