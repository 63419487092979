body {
	&.opened-menu {
		.header {
			&.header_inner,
			&.header_main {
				.lang {
					display: block;
					top: 30px;
					right: 25px;
					left: auto;
					transform: none;
					li {
						> a {
							color: #000;
							&:after {
								background: #fff; } }
						&.active {
							> a {
								color: #fff;
								&:after {
									background: transparent; }
								@include MQ(M) {
									color: $green; } } } } } } } } }

.header {
	.lang {
		display: none;
		position: absolute;
		font-size: 0.001px;
		margin: 0 -10px;
		li {
			display: inline-block;
			vertical-align: middle;
			padding: 0 10px;
			> a {
				font-size: 1.8rem;
				color: #000;
				font-family: $bold-font;
				border-bottom: 1px dotted #000;
				transition: all .2s linear; }
			&.active {
				> a {
					color: $green;
					border-bottom: 1px dotted transparent;
					&:hover {
						border-bottom: 1px dotted #000; }
					&:after {
						background-color: transparent; } } } } } }



@include MQ(SM) {
	.header {
		&.header_inner {
			.lang {
				display: inline-block;
				position: absolute;
				@include center(y);
				right: 20px;
				li {
					> a {
						color: #000;
						font-size: 1.6rem;
						border-bottom: 1px dotted #000;
						transition: all .2s linear;
						&:after {
							background: $green; } }
					&.active {
						> a {
							color: $green;
							border-bottom: 1px dotted transparent;
							&:hover {
								border-bottom: 1px dotted #000; }
							&:after {
								background: transparent; } } } } } } } }

@include MQ(M) {
	.header {
		.lang {
			position: static;
			display: inline-block;
			vertical-align: middle;
			margin: 0 -6px;
			li {
				padding: 0 6px;
				> a {
					color: #000;
					&:after {
						background: $green; }
					&:hover {
						&:after {
							background: transparent; } } } } } } }


@media (max-width: 860px) {
	.header {
		&.header_main {
			.lang {
				display: none; } } } }

@include MQ(L) {
	.header {
		&.header_inner {
			.lang {
				right: 40px;
				li {
					padding: 0 6px;
					> a {
						font-size: 1.8rem; } } } } } }
