.banner-container{
    display: none;
    background-color: #fbfbfb;
    padding-bottom: 90px;
    img{
        max-width: 100%;
        display: block;
        margin: 0 auto;
        height: auto;
    }
    @include MQ(M){
        display: block;
    }
}