@charset 'UTF-8';

// Default Variables

$slick-font-path: './fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .25 !default;

//@function slick-image-url($url) {
//  @if function-exists(image-url) {
//    @return image-url($url, false, false);
//  }
//  @else  {
//    @return url($slick-loader-path + $url);
//  }
//}
//
//@function slick-font-url($url) {
//  @if function-exists(font-url) {
//    @return font-url($url);
//  }
//  @else  {
//    @return url($slick-font-path + $url);
//  }
//}

/* Slider */

.slick-slider {
  position: relative;

  display: block;

  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;

  outline: none !important;
  &:focus {
    outline: none;
  }

  .slick-loading & {
    background: #fff url('../images/ajax-loader.gif') center center no-repeat;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;

  outline: none !important;
  &:before,
  &:after {
    display: table;

    content: '';
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;

  outline: none !important;
  [dir='rtl'] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
}

///* Icons */
//@if $slick-font-family == "slick" {
//  @font-face {
//      font-family:"slick";
//      src:    slick-font-url("slick.eot");
//      src:    slick-font-url("slick.eot?#iefix") format("embedded-opentype"),
//              slick-font-url("slick.woff") format("woff"),
//              slick-font-url("slick.ttf") format("truetype"),
//              slick-font-url("slick.svg#slick") format("svg");
//      font-weight: normal;
//      font-style: normal;
//  }
//}

.slick-dots {
  font-size: 0;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  li {
    display: inline-block;
    padding: 0 7px;
    vertical-align: middle;
    &.slick-active {
      button {
        background: $green;
      }
    }
    button {
      height: 15px;
      width: 15px;
      background: #ebebeb;
      position: relative;
      padding: 0;
      border-radius: 50%;
      outline: 0;
      transition: all .2s ease
    }
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  display: inline-block;
  position: absolute;
  transition: all .3s ease;
  @include center(y);
  &.slick-disabled {
    cursor: default;
    opacity: .6;
    //display: none !important;
  }
  [class^='icon-'] {
    font-size: 6.5rem;
    color: $green;
    opacity: .3;
    transition: all .3s ease;
  }
  &:hover {
    [class^='icon-'] {
      opacity: 1
    }
  }
}

.slick-prev {
  left: 5px
}
.slick-next {
  right: 5px
}

@media (min-width: 990px) {
  .slick-prev {
    left: -60px
  }
  .slick-next {
    right: -60px
  }
}

@media (min-width: 1200px) {
  .slick-prev {
    left: -160px
  }
  .slick-next {
    right: -160px
  }
}

.slick-vertical {
  .slick-prev,
  .slick-next {
    z-index: 5;
    top: initial;
    right: initial;
  }
  .slick-next {
    bottom: 0;
    text-indent: 1px;
    span {
      top: 1px;
    }
  }
  .slick-prev {
    top: 0;
    left: 50%;
  }
}

.slick-slider {
  margin-bottom: 0;
}
