.header {
	position: fixed;
	background: #fff;
	top: 0;
	left: 0;
	right: 0;
	height: 78px;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.07);
	z-index: 12;
	&.header_inner {
		.menu-logo {
			position: absolute;
			display: inline-block;
			vertical-align: middle;
			top: 0;
			right: 20px;
			bottom: 0;
			background: transparent;
			z-index: 11;
			@include vertical; }
		.logo-bg {
			display: none; }
		.logo {
			display: inline-block;
			vertical-align: middle;
			margin-right: -35px;
			width: 35px; }
		.logo-text {
			max-width: 145px;
			width: 100%;
			display: inline-block;
			vertical-align: middle;
			padding-top: 10px;
			padding-left: 35px;
			text-align: left;
			font-family: $bold-font;
			font-size: 1.5rem;
			color: #000; }
		.phone {
			display: none; }
		.j-btn_filled-white {
			display: none; } } }

body {
	@media (max-width: 768px) {
		&.opened-menu {
			position: fixed;
			overflow: hidden;
			height: 100%;
			width: 100%;
			.header {
				height: auto;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: hidden;
				overflow-y: auto;
				.main-menu {
					background: $main-color;
					height: auto;
					padding: 80px 20px 25px;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					text-align: left;
					z-index: 10;
					transition: background .3s ease;
					.phone_icon {
						display: none; } }
				.logo {
					display: none; }
				.logo-text {
					display: none; }
				.header-menu {
					display: block;
					margin-bottom: 30px; }
				.link {
					&.phone {
						text-shadow: none;
						position: relative;
						left: 0;
						font-size: 2.4rem;
						margin-bottom: 20px;
						transform: none; } }
				.j-btn_filled-white {
					display: block; }
				.social {
					display: block; }
				&.header_inner,
				&.header_main {
					.phone {
						display: inline-block;
						&:after {
							background: #000; } }
					.lang {
						display: block;
						top: 30px;
						right: 25px;
						left: auto;
						transform: none;
						li {
							.link {
								color: #fff;
								&:after {
									background: #fff; } }
							&.active {
								.link {
									color: #000;
									&:after {
										background: transparent; } } } } } } } } } }




.header {
	.burger {
		position: absolute;
		display: inline-block;
		width: 78px;
		height: 78px;
		background: $main-color;
		z-index: 11;
		.burger__trigger {
			position: absolute;
			display: inline-block;
			width: 38px;
			height: 30px;
			top: 24px;
			right: 20px;
			left: 20px;
			bottom: 24px;
			.line {
				position: absolute;
				display: block;
				height: 4px;
				background: #fbfbfb;
				top: 0;
				left: 0;
				right: 0;
				transition: all .3s ease;
				&:nth-child(2) {
					content: '';
					top: 50%;
					margin-top: -2px;
					left: 4px;
					right: 4px; }
				&:last-child {
					content: '';
					top: auto;
					left: 8px;
					right: 8px;
					bottom: 0; } } } }
	.main-menu {
		padding-left: 78px;
		height: 100%;
		@include vertical;
		text-align: left;
		.phone_icon {
			display: inline-block;
			vertical-align: middle;
			font-size: 2rem;
			color: $green;
			margin-left: 30px; } }
	.header-menu {
		display: none;
		li {
			display: block;
			padding: 7px 0;
			.link {
				display: inline-block;
				font-family: $bold-font;
				font-size: 3rem;
				transition: all .3s ease; } } }
	.link {
		&.phone {
			position: absolute;
			right: 20px;
			@include center(y);
			font-size: 2rem;
			color: #000; } }
	.social {
		display: none;
		padding-top: 20px; }
	.lang {
		display: none;
		position: absolute;
		font-size: 0.001px;
		margin: 0 -10px;
		li {
			display: inline-block;
			vertical-align: middle;
			padding: 0 10px;
			.link {
				font-size: 1.8rem;
				font-family: $bold-font; }
			&.active {
				.link {
					color: #000;
					&:after {
						background-color: transparent; } } } } } }

@media (max-height: 320px) {
	body.opened-menu {
		.header {
			.menu {
				margin-bottom: 15px;
				li {
					.link {
						font-size: 1.6rem; } } }
			.link {
				&.phone {
					margin-bottom: 10px; } } } } }

@media (max-height: 480px) {
	body.opened-menu {
		.header {
			.menu {
				margin-bottom: 15px;
				li {
					.link {
						font-size: 2rem; } } } } } }

@include MQ(S) {
	body.opened-menu {
		.header {
			.main-menu {
				text-align: center; }
			.j-btn_filled-white {
				margin: 0 auto; } } } }

@include MQ(SM) {
	.header {
		&.header_inner {
			.menu-logo {
				position: absolute;
				@include center(xy); }
			.lang {
				display: inline-block;
				position: absolute;
				@include center(y);
				right: 20px;
				li {
					.link {
						color: $green;
						font-size: 1.6rem;
						&:after {
							background: $green; } }
					&.active {
						.link {
							color: #000;
							&:after {
								background: transparent; } } } } } } } }

@include MQ(M) {
	.header {
		.burger {
			display: none; }
		.one-menu {
			.header-menu {
				vertical-align: top; } }
		.header-menu {
			display: inline-block;
			vertical-align: middle; }
		.menu {
			display: inline-block;
			margin: 0 -10px;
			li {
				display: inline-block;
				padding: 0 9px;
				.link {
					color: #000;
					font-size: 1.4rem;
					&:after {
						display: none; }
					&:hover {
						color: rgba(0, 0, 0, .7); } } }
			&__btn {
				display: inline-block;
				vertical-align: middle;
				margin-left: 33px;
				margin-right: 33px; } }
		.lang {
			position: static;
			display: inline-block;
			vertical-align: middle;
			margin: 0 -6px;
			li {
				padding: 0 6px;
				.link {
					color: $green;
					&:after {
						background: $green; }
					&:hover {
						&:after {
							background: transparent; } } } } }
		&.header_main {
			position: absolute;
			max-width: 1080px;
			width: 100%;
			height: auto;
			margin: 0 auto;
			background: transparent;
			box-shadow: none;
			.main-menu {
				height: auto;
				padding: 60px 20px 0;
				text-align: right; }
			.menu {
				height: 30px;
				vertical-align: top;
				@include vertical;
				li {
					vertical-align: bottom;
					.link {
						text-shadow: none;
						background: none;
						&:hover {
							padding-bottom: 10px; } } } } }
		&.header_inner {
			height: 70px;
			.main-menu {
				padding-left: 180px;
				padding-right: 80px;
				text-align: center;
				.phone_icon {
					display: none; } }
			.menu-logo {
				top: 0;
				left: 0;
				transform: none;
				display: inline-block;
				max-width: 180px;
				width: 100%;
				height: 100%;
				background: #000;
				@include vertical;
				.logo-bg {
					display: none; }
				.logo-text {
					color: #fff; } }
			.menu {
				vertical-align: middle;
				li {
					.link {
						text-shadow: none;
						position: relative;
						&:after {
							content: '';
							position: absolute;
							display: block;
							top: 40px;
							left: 0;
							width: 100%;
							height: 3px;
							background: $green;
							opacity: 0;
							visibility: hidden;
							transition: all .3s ease; }
						&:hover {
							&:after {
								visibility: visible;
								opacity: .3; } } }
					&.active {
						.link:after {
							visibility: visible;
							opacity: 1; } } }
				&__btn {
					display: none; } } } } }

@media (max-width: 860px) {
	.header {
		&.header_main {
			.menu {
				&__btn {
					margin-left: 30px;
					margin-right: 0; } }
			.lang {
				display: none; } } } }

@include MQ(L) {
	.header {
		.menu {
			margin: 0 -20px;
			li {
				padding: 0 15px;
				.link {
					font-size: 1.6rem; } } }
		&.header_inner {
			.main-menu {
				padding-left: 230px;
				padding-right: 104px; }
			.menu-logo {
				max-width: 220px;
				&:hover {
					.logo-bg {
						display: block; }
					.logo {
						display: none; }
					.logo-text {
						display: none; } } }
			.menu {
				&__btn {
					vertical-align: middle;
					margin-right: 0; } }
			.lang {
				right: 40px;
				li {
					.link {
						font-size: 1.8rem; } } } } } }

@media (min-width: 1232px) {
	.header {
		&.header_inner {
			.main-menu {
				.menu {
					&__btn {
						display: inline-block; } } } } } }

.header {
	@media (max-width: 500px) {
		&.header_inner {
			.menu-logo {
				position: static;
				padding-left: 20px; } } } }

@media (min-width: 1400px) {
	.header {
		.main-menu:not('.lang') {
			li {
				padding: 0 16px; } } } }

#header {
	transition: transform .25s ease-in-out;
	will-change: transform; }
.headroom--pinned {
    transform: translate(0, 0); }
.headroom--unpinned {
    transform: translate(0, -100%); }
