$height: 47px;
$width: 225px;
$radius: 24px;

.j-btn {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
  height: $height;
  width: $width;
  padding: 0 15px;
  text-align: center;
  user-select: none;
  line-height: 1;
  background-color: transparent;
  @include vertical;
  border-radius: 24px;
  transition: all .2s linear 0s;
  .help {
    color: #000;
    font-family: $bold-font;
    font: {
      size: 1.6rem; }
    transition: all .1s linear 0s; }
  &_filled-white {
    background-color: white;
    border: 2px solid white; }
  &_bordered-green {
    border: 2px solid $main-color;
    &:hover {
      background: $green;
      .help {
        color: #fff; } } } }

@mixin btn() {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
  height: $height;
  width: $width;
  padding: 0 15px;
  text-align: center;
  user-select: none;
  line-height: 1;
  background-color: transparent;
  border-radius: 24px;
  transition: all .2s linear 0s;
  border: 2px solid $main-color; }
@mixin default() {
  @include btn();
  border-color: $colorTextGray;
  color: $colorTextGray; }
