.article-list {
  padding-bottom: 50px;
  @include MQ(EXXL) {
    padding-bottom: 107px;
  }
}

.default-article {
  &:nth-child(2n) {
    .text-wrap {
      padding-top: 25px;
      p:last-child {
        padding-bottom: 15px;
      }
    }
  }
  h3 {
    padding-bottom: 20px;
  }
  position: relative;
  &:not(:last-child) {
    padding-bottom: 25px;
  }
  .image-wrap {
    margin-bottom: 25px;
    background: {
      repeat: no-repeat;
      position: top left;
    }
    height: 343px;
    background-size: cover;
    &.special {
      box-shadow: 0 29px 89px rgba(0, 0, 0, 0.1);
      background: {
        position: center 0;
      }
    }
  }
  .text-wrap {
    padding: {
      left: $containerOuterSpace_mobile;
      right: $containerOuterSpace_mobile;
    }
  }
  @include MQ(L) {
    &:nth-child(2n) {
      .image-wrap {
        height: 100%;
      }
    }
    min-height: 390px;
    @include clearfix();
    padding-bottom: 0;
    .text-help {
      max-width: $containerWidth/2;
      width: 100%;
      float: right;
      padding: {
        right: 30px;
        left: $containerOuterSpace;
      }
    }
    .text-wrap {
      float: left;
      position: relative;
      z-index: 1;
      width: 50%;
      padding: {
        top: 30px;
        left: 0;
        right: 0;
        bottom: 30px;
      }
    }
    .image-wrap {
      margin-bottom: 0;
      position: absolute;
      height: 100%;
      right: 0;
      width: 50%;
      background: {
        position: left center;
      }
      background-size: auto 100%;
      top: 0;
      &.special {
        box-shadow: none;
      }
    }
  }
  @include MQ(EXL) {
    .text-wrap {
      padding: {
        top: 80px;
        bottom: 45px;
      }
    }
    .text-help {
      padding: {
        right: 100px;
      }
    }
  }
}

.article-list {
  @include MQ(L) {
    .default-article {
      &:nth-child(2n) {
        .image-wrap {
          left: 0;
          right: auto;
        }
        .image-wrap {
          background-position: right center;
        }
        .text-wrap {
          float: right;
        }
        .text-help {
          padding: {
            right: $containerOuterSpace;
            left: 30px;
          }
          float: left;
        }
      }
    }
  }
  @include MQ(EXL) {
    h3 {
      text-align: left;
    }
    .default-article {
      &:nth-child(2n) {
        .text-help {
          padding: {
            right: 65px;
          }
        }
      }
    }
  }
  @include MQ(EXXL) {
    .default-article {
      .text-wrap {
        padding: {
          top: 62px;
        }
      }
      .text-help {
        padding: {
          left: 185px;
        }
        line-height: 1.5;
        font-size: 16px;
      }
      .image-wrap {
        &.special {
          background: {
            position: 30px top;
          }
        }
      }
    }
    .default-article {
      &:not(:last-child) {
        padding-bottom: 0;
      }
    }
    .default-article {
      &:nth-child(2n) {
        .text-wrap {
          padding: {
            top: 62px;
          }
        }
        .text-help {
          padding: {
            left: 60px;
          }
        }
      }
    }
  }
}