
.section-about {
  .triple-block {
    opacity: 0;
    transition: all .3s linear;
    transform: translateY(50px);
    &.fade-in {
      opacity: 1;
      transform: translateY(0);
    }
  }
}