.heading-help {
    padding: {
        bottom: 25px;
    }
    &.special {
        position: absolute;
        top: 3.5%;
        width: 100%;
        z-index: 1;
        padding: {
            bottom: 0;
        }
        margin-left: -20px;
        @include MQ(EXL) {
            
            .heading-wrap {
                float: none;
            }
            
        }
    }
}

.heading-help {
    @include MQ(EXM) {
        width: 50%;
        @include clearfix();
        &.special {
            @include clearfix();
            width: 50%;
            top: 0.5%;
            margin-left: 0;
        }
    }
    @include MQ(L) {
        width: 50%;
        .heading-wrap {
            max-width: $containerWidth/2;
            float: right;
            width: 100%;
            padding: {
                // left: 40px;
                right: 30px;
            }
        }
        &.special {
            .heading-wrap {
                padding: {
                    bottom: 0;
                }
            }
        }
    }
    @include MQ(EXL) {
        padding: {
            bottom: 55px;
        }
        .heading-wrap {
            padding: {
                right: 100px;
            }
        }
        h3 {
            text-align: left;
        }
    }
}