.doctor-who-list {
  >.form-item {
    width: 100%;
  }
  .form-radios {
    font-size: 0;
  }
  .form-type-radio {
    label {
      margin: 0;
    }
    .form-radio {
      display: none;
      padding-bottom: 0;
    }
    padding-bottom: 30px;
    cursor: pointer;
    &.active {
      .who-photo {
        border: 3px solid $green;
      }
      .who-name {
        color: rgba(black, 1);
      }
    }
  }
  font-size: 0;
  .who-photo {
    width: 69px;
    height: 69px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    margin-right: -69px;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    border: 3px solid #ffffff;
    transition: all .01s ease;
    &:before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border: 5px solid #ffffff;
      border-radius: 50%;
    }
  }
  .who-name {
    font-size: 18px;
    width: 100%;
    padding-left: 82px;
    max-width: 266px;
    color: rgba(black, .5);
    transition: $transition_options;
    @include MQ(EXM) {
      font-size: 16px;
    }
  }
  .who-photo,
  .who-name {
    display: inline-block;
    vertical-align: middle;
  }
}

.doctor-who-divider {
  background: $colorTextGray;
  color: #ffffff;
  font-family: $bold-font;
  padding: {
    top: 10px;
    bottom: 10px;
  }
  margin-bottom: 25px;
  @include minusMargins($containerOuterSpace_mobile);
  &.success {
    background: $green;
  }
  p {
    text-align: center;
  }
}

@include MQ(EXM) {
  .doctor-who-divider {
    @include minusMargins(65px);
  }
  .doctor-who-list {
    font-size: 0;
    .form-type-radio {
      width: 50%;
      display: inline-block;
      vertical-align: middle;
      &:nth-last-child(-2n+2), &:last-child {
        padding-bottom: 30px;
      }
    }
  }
}