.header-menu {
  .leaf {
    a {
      font-size: 3rem;
      position: relative;
      display: inline-block;
      text-decoration: none;
      line-height: 1;
      transition: all .3s ease;
      color: #fff;
      font-family: $bold-font;
      @include link-underline($green, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), #93DFB8);
      text-shadow: none;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all .3s ease;
      }
      &:hover {
        text-decoration: none;
        opacity: .7;
        &:after {
          opacity: 1;
          visibility: visible;
          color: #000;
          opacity: .3;
        }
      }
    }
  }
}
.webform-appointment-main {
  height: 0;
}

@include MQ(M) {
  .header-menu {
    .leaf {
      a {
      &.active {
        &:after {
          visibility: visible;
          opacity: 1;
        }
      }  
      &:after {
      content: '';
      position: absolute;
      display: block;
      top: 40px;
      left: 0;
      width: 100%;
      height: 3px;
      background: #16d690;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease;

        }
      color: #000;
      font-size: 1.4rem;      


      }
    }
  }
}
@include MQ(L) {
  .header-menu {

    .leaf {
      a {  
        font-size: 1.6rem;
      }   
    }
  }
  .one-menu {
    .leaf {
      &:hover {
        a {
          padding-bottom: 10px;
        }
      }
    }
  }

}
