.modal {
    &.thanx-modal {
        .modal-content {
            padding: {
                top: 40px;
                bottom: 40px;
            }
            text-align: center;
            .msg {
                font-size: 18px;
                padding-bottom: 24px;
                padding: {
                    left: 10px;
                    right: 10px;
                }
            }
            .advice {
                font-size: 14px;
                color: $colorTextGray;
            }
            .close {
                top: -4px;
            }
            @include MQ(EXM) {
                padding: {
                    bottom: 50px;
                    left: 0;
                    right: 0;
                }
                .form-inner {
                    padding: {
                        left: 125px;
                        right: 125px;
                    }
                }
                .close {
                    top: 0;
                }
            }
        }
    }
}