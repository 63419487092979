.review {
	position: relative;
	.author {
		margin-bottom: 20px;
		font-size: 0.001px;
		&__img {
			display: inline-block;
			vertical-align: middle;
			width: 90px;
			margin-right: -90px;
			img {
				width: 100%; } }
		&__info {
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			padding-left: 115px;
			span {
				display: block;
				line-height: 1.4; }
			.name {
				font-family: $bold-font;
				font-size: 1.8rem; }
			.date {
				margin-bottom: 8px;
				font-size: 1.6rem; } } }
	&__text {
		margin-bottom: 20px;
		p {
			font-size: 1.8rem;
			color: rgba(0,0,0,.7);
			line-height: 1.222; } }
	.therapist {
		display: inline-block;
		position: relative;
		max-width: 315px;
		width: 100%;
		background: #f5f5f5;
		border-radius: 50px;
		@include vertical;
		text-align: left;
		&__img {
			display: inline-block;
			vertical-align: middle;
			width: 74px;
			height: 74px;
			margin-right: -74px;
			img {
				width: 100%;
				height: 100%;
				border: 3px solid #fff;
				border-radius: 50px; } }
		&__name {
			display: inline-block;
			vertical-align: middle;
			padding-left: 88px;
			padding-right: 20px;
			span {
				display: block;
				text-align: left;
				font-size: 1.6rem;
				color: rgba(0,0,0,.7);
				line-height: 1.2; } } } }


@include MQ(M) {
	.review {
		&__text {
			p {
				font-size: 1.6rem;
				line-height: 1.375; } }
		.therapist {
			&__img {
				width: 56px;
				height: 56px;
				margin-right: -56px; }
			&__name {
				padding-left: 66px;
				padding-right: 10px;
				span {
					font-size: 1.4rem; } } } } }
