.about-item {
	.layer {
		position: relative;
		.image {
			&_bg {
				filter: grayscale(100%);
				opacity: .1; } }
		&__group {
			figure {}
			.text-holder {} } } }
