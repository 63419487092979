.services-inner, .news-inner {
	padding-top: 10px;
	.container {
		position: relative;
		padding-top: 20px;
		max-width: 1285px; }
	.article-img {
		max-width: 460px;
		margin-bottom: 20px;
		img {
			max-width: 100%;
			height: auto; } }
	.content {
		margin-bottom: 50px;
		&__group {
			&:not(:last-child) {
				margin-bottom: 25px; } }
		&__img {
			max-width: 460px;
			margin-bottom: 20px;
			img {
				max-width: 100%;
				height: auto; } }
		&__title {
			display: block;
			font-family: $bold-font;
			font-size: 1.8rem;
			margin-bottom: 10px; }
		p {
			line-height: 1.2; } }


	.others-wrap {
		margin-bottom: 45px;
		.title {
			display: block;
			margin-bottom: 20px;
			font-size: 2.4rem;
			font-family: $bold-font; } }
	.others {
		li {
			display: block;
			margin-bottom: 15px;
			a {
				display: block; }
			.img-wrap {
				display: none; }
			.link {
				display: inline;
				color: #000;
				font-family: $bold-font;
				font-size: 1.8rem;
				opacity: .5;
				transition: all .3s ease;
				&:not(:last-child) {
					margin-bottom: 20px; }
				&:hover {
					opacity: 1; } } } } }

@include MQ(M) {
	.services-inner, .news-inner {
		padding-top: 50px;
		text-align: center;
		.article-wrap {
			max-width: 550px;
			margin: 0 auto; }
		.article-img {
			margin-bottom: 0; }
		.content {
			margin-bottom: 90px;
			text-align: left;
			p {
				font-size: 1.6rem;
				line-height: 1.5; }
			&__group {
				&:not(:last-child) {
					margin-bottom: 40px; } }
			&__img {
				&_blurred {
					overflow: hidden;
					img {
						filter: blur(5px);
						transition: all .2s ease; }
					&:hover {
						img {
							filter: blur(0px); } } } } }
		.others-wrap {
			margin-bottom: 95px;
			.title {
				display: none; } }
		.others {
			font-size: 0;
			li {
				width: 100%;
				display: inline-block;
				vertical-align: middle;
				a {
					height: 100%;
					padding: 35px 30px 25px 40px;
					border: 7px solid transparent;
					transition: all .3s ease;
					opacity: .5;
					@include vertical;
					text-align: left;
					&:hover {
						border: 7px solid $green;
						opacity: 1; } }
				.img-wrap,
				.text-wrap {
					display: inline-block;
					vertical-align: middle;
					img {
						max-width: 100%;
						display: block;
						margin: auto;
						height: auto; }
					.link {
						display: inline;
						font-size: 2.4rem;
						opacity: 1; } }
				.text-wrap {
					width: 100%; }
				&:first-child {
					max-width: 445px;
					.img-wrap {
						width: 200px;
						margin-right: -200px; }
					.text-wrap {
						padding-left: 213px; } }
				&:nth-child(2) {
					max-width: 362px;
					.img-wrap {
						max-width: 100px;
						margin-right: -100px; }
					.text-wrap {
						padding-left: 125px; } }
				&:last-child {
					max-width: 395px;
					.img-wrap {
						max-width: 100px;
						margin-right: -100px; }
					.text-wrap {
						padding-left: 125px; } } } } } }


@media (min-width: 1100px) {
	.services-inner, .news-inner {
		.article-img {
			max-width: 250px;
			position: absolute;
			left: 20px; } } }
