.player {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat; } }
.player-wrapper {
    position: relative; }
.fluid-width-video-wrapper {
    height: 100%;
    iframe {
        z-index: 11; }
    @include MQ(L) {
        iframe {
            z-index: 0; } } }




