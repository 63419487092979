.article {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 3px;
		background: $green; }
	&__img, a {
		display: inline-block;
		width: 100%;
		img {
			width: 100%;
			height: auto; } }
	&__group {
		padding: 0 0 15px; }
	&__date,
	&__views {
		font-size: 0.001px;
		.text {
			display: inline-block;
			vertical-align: middle;
			color: rgba(0,0,0,.7);
			font-size: 1.4rem; } }
	&__date {
		float: left;
		padding: 10px;
		background: #f5f5f5; }
	&__views {
		float: right;
		padding: 10px 10px 10px 0;
		[class^='icon-'] {
			display: inline-block;
			vertical-align: middle;
			margin-right: 4px;
			color: $green;
			font-size: 1rem; } }
	&__preview {
		display: block;
		padding-top: 10px;
		.h5 {
			display: inline;
			line-height: 1.2; }
		p {
			padding-top: 10px;
			color: rgba(0,0,0,.7);
			font-size: 1.8rem;
			line-height: 1.222; } } }

@include MQ(M) {
	.article {
		padding-top: 36px;
		&:before {
			top: 36px; }
		&__group {
			position: absolute;
			top: 0;
			padding: 0; }
		&__date {
			background: transparent;
			padding-left: 0; }
		&__views {
			display: none; }
		&__preview {
			p {
				font-size: 1.6rem;
				line-height: 1.375; } } } }
