
.first-screen {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	background: url('/sites/all/themes/dentalv/images/mob-first-screen.jpg') no-repeat;
	background-size: cover;
	background-position: center;
	height: calc(100vh - 78px);
	@include MQ(L) {
		box-shadow: 0 5px 40px 4px rgba(0, 0, 0, 0.05); }
	transition: all .1s linear;
	will-change: scroll-position;
	&.destroied {
		display: none; }
	.header {
		display: none; }
	.logo-group {
		padding: 0 20px;
		display: inline-block;
		position: absolute;
		@include center(xy);
		.block-logo {
			font-size: 0.001px;
			padding-bottom: 90px;
			.logo-img {
				width: 75px;
				display: inline-block;
				vertical-align: middle;
				margin-right: -75px;
				img {
					width: 100%; } }
			.logo-text {
				display: inline-block;
				vertical-align: middle;
				padding-top: 20px;
				padding-left: 75px;
				.logo-help {
					display: none; } } }
		.block-btn {
			text-align: center; } } }

.short-about {
	position: relative;
	margin-bottom: 30px;
	.h3 {
		padding: 40px 20px 0;
		position: absolute;
		width: 100%;
		text-align: center; } }
.about-slider {
	.triple-block {
		.above-image {
			figure {
				padding-top: 90px; }
			&.particular {
				figure {
					padding-top: 90px; } }
			&.special {
				figure {
					padding-top: 130px; } } }
		.bg-image {
			margin-bottom: 166px;
			&.special {
				margin-bottom: 120px; } } }
	&.destroied {
		padding-top: 50px; } }
.video-about {
	cursor: pointer;
	position: relative;
	min-height: 500px;
	height: 1px;
	margin-bottom: 50px;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,.7);
		z-index: 10;
		visibility: visible;
		opacity: 1; }
	&.clicked {
		&:after {
			content: none; } }
	&.playing {
		&:after,
		.video-text {
			visibility: hidden;
			opacity: 0; } } }

.special-offers {
	margin-bottom: 35px;
	.container {
		max-width: 1154px;
		.h3 {
			margin-bottom: 15px;
			text-align: center; } }
	.offers-wrap {
		padding-bottom: 20px;
		margin-right: -$containerOuterSpace_mobile;
		overflow: auto;
		.offers {
			font-size: 0.001px;
			width: 1074px;
			margin: 0 -7px;
			li {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				padding: 0 7px;
				width: 25%;
				height: 375px;
				a {
					padding: 115px 20px 20px;
					display: inline-block;
					position: absolute;
					top: 0;
					right: 7px;
					bottom: 0;
					left: 7px;
					.title {
						display: inline;
						font-size: 2.4rem;
						color: #fff;
						line-height: 1.2;
						border-bottom: 1px solid transparent;
						transition: all .3s ease;
						.bold {
							font-family: $bold-font; } }
					.terms {
						display: block;
						position: absolute;
						bottom: 20px;
						left: 20px;
						color: #fff;
						font-size: 1.6rem;
						line-height: 1.2; }
					&:hover {
						.title {
							border-bottom: 1px solid rgba(255, 255, 255, .3);
							&:hover {
								border-bottom: 1px solid rgba(255, 255, 255, 1); } } } }
				&.certificate {
					border-radius: 10px;
					.terms {
						text-decoration: underline; } } } } } }

.clinic-articles {
	padding-bottom: 60px;
	.container {
		max-width: 830px;
		text-align: center;
		padding: 0;
		.h3 {
			display: inline-block;
			margin-bottom: 25px;
			text-align: center; } }
	.articles-slider {
		width: 100%;
		text-align: left;
		.article {
			margin: 0 $containerOuterSpace_mobile; }
		.slick-dots {
			padding-top: 40px; } } }

.clinic-reviews {
	padding-bottom: 40px;
	.container {
		max-width: 830px;
		text-align: center;
		padding: 0;
		.h3 {
			display: inline-block;
			margin-bottom: 20px;
			text-align: center; } }
	.reviews-slider {
		width: 100%;
		text-align: left;
		.review {
			margin: 0 $containerOuterSpace_mobile; }
		.slick-dots {
			padding-top: 20px; } } }

.section-map {
	height: 210px;
	#map {
		height: 100%; } }

@include MQ(M) {
	.first-screen {
		background: url('/sites/all/themes/dentalv/images/first-screen.jpg') no-repeat;
		background-size: cover;
		height: 100vh;
		z-index: 12;

		.header {
			display: block; }
		.logo-group {
			max-width: 520px;
			.block-logo {
				padding-bottom: 0;
				.logo-img {
					width: 120px;
					margin-right: -120px; }
				.logo-text {
					padding-top: 70px;
					padding-left: 120px;
					.logo-help {
						padding-top: 10px;
						display: block;
						color: $green;
						font-size: 1.8rem;
						font-family: $bold-font; } } }
			.block-btn {
				position: absolute;
				@include center(x);
				bottom: -21vh; } } }
	.about-slider {
		.above-image.special figure:before, .triple-block .above-image.special figure:after {
			top: -175px;
			height: 306px; }
		&.destroied {
			padding-top: 100px;
			padding-bottom: 50px; } }
	.short-about {
		.h3 {
			padding-top: 0; } }
	.video-about {
		position: relative;
		min-height: 680px;
		margin-bottom: 75px; }
	.special-offers {
		margin-bottom: 70px;
		.container {
			.h3 {
				margin-bottom: 35px; } }
		.offers-wrap {
			margin-right: -$containerOuterSpace; } }
	.clinic-articles {
		background: #fbfbfb;
		padding-top: 55px;
		margin-bottom: 60px;
		.container {
			.h3 {
				margin-bottom: 70px; } }
		.articles-slider {
			position: relative;
			.article {
				margin: 0 $containerOuterSpace; } } }
	.clinic-reviews {
		padding-bottom: 75px;
		.container {
			.h3 {
				margin-bottom: 60px; } }
		.reviews-slider {
			.review {
				margin: 0 $containerOuterSpace; } } }
	.section-map {
		height: 415px; } }

@include MQ(L) {
	.first-screen {
		position: fixed; }
	.short-about {
		width: 4000px;
		height: 860px;
		margin-bottom: 60px;
		padding-top: 116px;
		height: 100vh;
		&.resized {
			width: auto;
			.about-slider {
				.triple-block {
					width: 1200px;

					margin-right: 50px;
					margin-left: 50px; } } }
		.h3 {
			// max-width: 100vw
			// position: relative
			// padding: 50px 0
			position: absolute;
			display: inline-block;
			padding-left: 36vw;
			width: initial;
			top: 0; } }
	.about-slider {
		height: 100vh;
		padding-top: 7vh;
		transform: translate(-1.70662%, 0%) translate3d(0px, 0px, 0px);
		.triple-block {
			max-width: 690px;
			.above-image {
				&.special {
					figure:after, figure:before {
						height: 306px; } } }
			&:nth-of-type(odd) {
				.triple-wrap {
					margin-left: -100px; } }
			&:nth-of-type(even) {
				float: left;
				padding-top: 13vh;
				padding-bottom: 5vh;
				margin-right: 200px;
				.triple-wrap {
					margin-left: -150px; } } } } }

@media (min-width: 1154px) {
	.special-offers {
		.offers-wrap {
			overflow: hidden;
			.offers {
				width: 100%;
				margin: 0; } } } }

@media (min-width: 1350px) {
	.short-about {
		width: 3170px; } }

@media (max-width: 1024px) {
	.special-offers {
		.offers-wrap {
			&::-webkit-scrollbar {
				display: none; } } } }

@media (max-height: 380px) {
	.first-screen {
		.logo-group {
			.block-logo {
				padding-bottom: 20px; } } } }
@media (min-width: 1222px) {
	.video-about {
		.fluid-width-video-wrapper {
			padding-top: 40% !important; } } }
