.error-page {
	padding-top: 19vh;
	padding-bottom: 19vh;
	.container {
		max-width: 320px; }
	&__img {
		display: block;
		max-width: 256px;
		width: 100%;
		margin-bottom: 15px; }
	&__content {
		.link {
			margin-top: 15px;
			font-size: 1.8rem;
			&:hover {
				color: $green; } } }
	&__title {
		display: block;
		font-family: $bold-font;
		font-size: 2.4rem; } }

@include MQ(M) {
	.error-page {
		.container {
			max-width: 920px;
			@include vertical;
			font-size: 0.001px; }
		&__img {
			display: inline-block;
			vertical-align: middle;
 }			//margin-right: -256px
		&__content {
			display: inline-block;
			vertical-align: middle;
			//padding-left: 286px
			text-align: left;
			.link {
				font-size: 1.6rem; } }
		&__title {
			font-size: 4.8rem; } } }



@media (max-height: 400px) {
	.error-page {
		padding-top: 20px; } }
