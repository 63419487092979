//@import "checkbox"
//@import "radiobutton"
.modal {
  &.custom-modal {
    padding: 0 !important;
    .modal-content {
      padding: {
        left: $containerOuterSpace_mobile;
        right: $containerOuterSpace_mobile;
        top: 40px;
        bottom: 0; }
      @include MQ(EXM) {
        padding: {
          left: 65px;
          right: 65px;
          top: 35px; } } }
    .modal-dialog {
      margin: {
        top: 78px; }
      @include MQ(M) {
        margin: {
          top: 70px; } }
      @include MQ(EXM) {

        .modal-content {
          max-width: 640px;
          border-radius: 10px;
          margin: 0 auto; } }
      @include MQ(EXXL) {
        margin-top: 170px; } } } }

.webform-component-textarea {
  .grippie {
    display: none; } }
.form-inner, .form-search {
    position: relative;
    .close {
      right: 0;
      top: 0; }
    h3 {
      font-size: 24px;
      padding: {
        bottom: 35px; }
      &.special {
        color: $green;
        padding-right: 33px;
        text-align: center; } }
    .current-vacancy {
      color: #000000; }
    .form-item {
      position: relative; }
    label {
      color: #000;
      font-size: 18px;
      margin-bottom: 12px;
      display: inline-block;
      cursor: pointer;
      font-family: $bold_font;
      a {
        color: #fff;
        text-decoration: underline;
        &:hover {
          text-decoration: none; } } }
    .input-covering {
      padding-bottom: 30px;
      font-size: 0;
      .help-blocks {
        text-align: left;
        font-size: 12px;
        display: block;
        margin-top: 6px;
        color: #567B9E; }
      &:last-child {
        padding-bottom: 0; }
      &_cu-error {
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="password"],
        input[type="search"],
        textarea {
          border: 2px solid #ff0b05; } }
      &_cu-success {
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="password"],
        input[type="search"],
        textarea {
          border: $border_options_focus; } } }

    .form-item {}

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="password"],
    input[type="search"],
    textarea {
      background: #fff;
      outline: 0;
      color: #000;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      width: 100%;
      border: $border_options;
      transition: $transition_options;
      border-radius: 0;
      &::placeholder {
        color: #87888a;
        border: #000;
        text-transform: none;
        &::placeholder {
          color: transparent; } } }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="search"],
    input[type="password"] {
      height: 60px;
      padding: 0 15px; }
    input[type="search"] {
      -webkit-appearance: none; }
    input[type="password"] {
      text-transform: none!important; }
    textarea {
      resize: none;
      padding: 10px 15px;
      height: 160px; }
    .form-item {
      padding-bottom: 30px;
      margin: 0;
      &:last-child, &:last-of-type {
        padding-bottom: 0; } }
    .form-btn {
      width: 100%;
      height: 80px;
      font: {
        size: 18px;
        family: $bold_font; }
      color: #ffffff;
      background: $colorTextGray;
      &:focus {
        outline: none; }
      &.success {
        background: $green; } }
    .form-btn-wrap {
      @include minusMargins($containerOuterSpace_mobile); }
    .input-goup {
      padding-bottom: 40px;
      font-size: 0; } }

.form-inner-custom {
    position: relative;
    .close {
      right: 0;
      top: 0; }
    h3 {
      font-size: 24px;
      padding: {
        bottom: 35px; }
      &.special {
        color: $green;
        padding-right: 33px;
        text-align: center; } }
    .current-vacancy {
      color: #000000; }
    .form-item {
      position: relative; }
    label {
      color: #000;
      font-size: 16px;
      margin-bottom: 12px;
      display: inline-block;
      cursor: pointer;
      font-family: $bold_font;
      a {
        color: #fff;
        text-decoration: underline;
        &:hover {
          text-decoration: none; } } }
    .input-covering {
      padding-bottom: 30px;
      .help-blocks {
        text-align: left;
        font-size: 12px;
        display: block;
        margin-top: 6px;
        color: #567B9E; }
      &:last-child {
        padding-bottom: 0; }
      &_cu-error {
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="password"],
        input[type="search"],
        textarea {
          border: 2px solid #ff0b05; } }
      &_cu-success {
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="password"],
        input[type="search"],
        textarea {
          border: $border_options_focus; } } }
    .form-item {}

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="password"],
    input[type="search"],
    textarea {
      background: #fff;
      outline: 0;
      color: #000;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      width: 100%;
      border: $border_options;
      transition: $transition_options;
      border-radius: 0;
      &::placeholder {
        color: #87888a;
        border: #000;
        text-transform: none;
        &::placeholder {
          color: transparent; } } }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="search"],
    input[type="password"] {
      height: 60px;
      padding: 0 15px; }
    input[type="search"] {
      -webkit-appearance: none; }
    input[type="password"] {
      text-transform: none!important; }
    textarea {
      resize: none;
      padding: 10px 15px;
      height: 240px; }
    .form-item {
      padding-bottom: 30px;
      margin: 0;
      &:last-child, &:last-of-type {
        padding-bottom: 0; } }
    .form-btn {
      font: {
        size: 18px;
        family: $bold_font; }
      @include default();
      width: 182px;
      &:focus {
        outline: none; }
      &.success {
        border-color: $green;
        color: #000000;
        transition: $transition_options;
        &:hover {
          background: $green;
          color: #000000; } } }
    .input-goup {
      padding-bottom: 30px; } }
.form-inner {
  @include MQ(EXM) {
    position: static;
    .close {
      width: 47px;
      height: 47px;
      margin-right: -77px; }
    .close:before, .close:after {
      height: 57px;
      background: rgba(255, 255, 255, 1);
      left: 20px;
      width: 1px;
      top: -10px; }
    h3 {
      text-align: center;
      font-size: 30px;
      &.special {
        padding-right: 0; } }
    .form-btn {
      border-radius: 0  0 10px 10px;
      transition: $transition_options; }
    .input-covering {
      .help-blocks {
        text-align: right; } }
    .form-item {
      input[type="text"],
      input[type="tel"],
      input[type="email"],
      input[type="password"],
      input[type="search"],
      textarea {
        transition: $transition_options; }
      font-size: 0; }
    .form-btn-wrap {
      @include minusMargins(65px); }
    .controls-label, .form-item {
      display: inline-block; }
    .controls-label {
      vertical-align: middle;
      font-size: 16px;
      width: 30%;
      text-align: right;
      padding-right: 25px;
      &.special {
        vertical-align: top;
        padding-top: 20px; } }
    .form-item {
      width: 70%;
      font-size: 18px; } } }













