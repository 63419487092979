// breakpoints
$S: 480px;
$SM: 640px;
$M: 768px;
$EXM: 800px;
$L: 1025px;
$EXL: 1245px;
$EXXL: 1345px;
$XXL: 1500px;

$line_opacity: .3;
$primary-font: 'Museo Sans', sans-serif;
$bold-font: 'Museo Cyrl', serif;


$containerWidth: 1350px;
$containerOuterSpace: 40px;
$containerOuterSpace_mobile: 20px;

$green: #16d690;
$main-color: $green;
$colorTextGray: #b7b7b7;

$border_options: 2px solid $colorTextGray;
$border_options_focus: 2px solid $green;
$transition_options: all .1s linear 0s;





