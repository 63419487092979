html {
  height: 100%; }
body {
  height: 100%;
  line-height: 1;
  @include font-smoothing;
  //height: 100%
  width: auto;
  font: {
    family: $primary-font;
    weight: 400;
    style: normal;
    size: 1.4rem; }
  direction: ltr;
  overflow-x: hidden;
  background: #fff;
  &::before {
    content: 'mobile';
    display: none; }
  @include MQ(L) {
    &::before {
      content: 'desktop'; } } }

.wrapper {
  min-height: 100%;
  position: relative;
  margin: 0 auto -285px;
  padding: 78px 0 285px;
  overflow: hidden;
  @include MQ(M) {
    margin-bottom: -185px;
    padding-bottom: 185px;
    &.main-page {
      padding-top: 0; } }
  @media (min-width: 900px) {
    margin-bottom: -145px;
    padding-bottom: 145px; }
  @include MQ(L) {
    margin-bottom: -345px;
    padding-bottom: 345px; } }


.container {
  padding-right: $containerOuterSpace_mobile;
  padding-left: $containerOuterSpace_mobile;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: $containerWidth;
  width: 100%;
  @include MQ(EXM) {
    padding-right: $containerOuterSpace;
    padding-left: $containerOuterSpace; } }

.help {
  display: inline-block; }

.link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  line-height: 1;
  transition: all .3s ease;
  color: #000;
  &_bold {
    font-family: $bold-font; }
  &_green {
    color: $main-color; }
  &_white {
    color: #fff; }
  &_bordered {
    &_green {
      @include link-underline(#fff, rgba(22, 214, 144, 1),  rgba(22, 214, 144, .3), #93DFB8); }
    &_black {
      @include link-underline(#fff, rgba(0, 0, 0, 1),  rgba(0, 0, 0, .3), #93DFB8); }
    &_white {
      @include link-underline($green, rgba(255, 255, 255, 1),  rgba(255, 255, 255, .3), #93DFB8); } } }

.main-nav {
  .link {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .3s ease; }
    &:hover {
      text-decoration: none;
      &:after {
        opacity: 1; } }
    &_bold {
      font-family: $bold-font; }
    &_green {
      color: $main-color; }
    &_white {
      color: #fff; }
    &_bordered {
      &_green {
        text-shadow: none;
        &:after {
          background: $main-color;
          opacity: .3; } }
      &_black {
        text-shadow: none;
        &:after {
          color: #fff;
          opacity: .3; } }
      &_white {
        text-shadow: none;
        &:after {
          color: #000;
          opacity: .3; } } } } }

.image {
  img {
    width: 100%; } }

figure {
  margin: 0; }

@include MQ(M) {
  .show-for-small {
    display: none !important; }
  .hide-for-small {
    display: block; } }
