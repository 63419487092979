    
    .fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object, .fluid-width-video-wrapper embed {
        position:absolute;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        width:100%;
        height:100%;
    }


.coub-i {
    	position:relative;
        padding-bottom:56.25%;
        height:0;
        overflow:hidden;
    .coub-i iframe, .coub-i object, .coub-i embed {
        position:absolute;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        width:100%;
        height:100%;
    }
    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .video-text{
        position: static;
    }
    @include MQ(M) {

    }
    @include MQ(L) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 0;
        height: auto;
        padding: 0;
        .coub-inner{
            font-size: 0;
            position:relative;
            padding-bottom:56.25%;
            height:0;
            overflow:hidden;
        }
        .video-text{
            display: none;
        }
    }
}