.breadcrumbs {
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	a {
		position: relative;
		display: inline-block;
		padding-right: 10px;
		background: #fff;
		font-family: $bold-font;
		font-size: 1.6rem;
		color: rgba(0,0,0,.5);
		transition: color .3s ease;
		z-index: 1;
		max-width: 200px;
		&:hover {
			color: rgba(0,0,0,1); } }
	&:after {
		content: '';
		position: absolute;
		top: 5px;
		height: 1px;
		width: 100%;
		background: $green; } }
.crumb-title {
	padding: 0 0 20px;
	font-size: 3rem;
	line-height: 1.2;
	text-align: left; }

@include MQ(M) {
	.breadcrumbs {
		margin-bottom: 0;
		position: absolute;
		top: 35px;
		right: 50%;
		left: 40px;
		text-align: left; }
	.crumb-title {
		position: relative;
		display: inline-block;
		padding: 0 10px 50px;
		background: #fff;
		z-index: 1; } }

@include MQ(L) {
	.crumb-title {
		text-align: center; } }
