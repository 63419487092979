.section-about {
  &_custom {
    padding-top: 37px;
  }
  .container {
    &.special {
      position: static;
    }
  }
  h1,
  h3 {
    text-align: center;
  }
  h1 {
    padding-bottom: 18px;
  }
  h3 {
    font-size: 24px;
    @include MQ(EXL) {
      text-align: left;
    }
    @include MQ(EXXL) {
      font-size: 30px;
    }
  }
  .section-history {
    padding-bottom: 50px;
    position: relative;
  }
  .section-team {
    padding-bottom: 50px;
    .team-list {
      @include minusMargins($containerOuterSpace_mobile);
    }
  }
  .section-vacancy {
    .vacancy-list {
      @include minusMargins($containerOuterSpace_mobile);
    }
    padding-bottom: 50px;
    padding-top: 30px;
  }
} //ends section-about
.section-about {
  @include MQ(M) {
    &_custom {
      padding-top: 56px;
    }
  }
  @include MQ(SM) {
    .section-team {
      .team-list {
        font-size: 0;
      }
    }
    .section-vacancy {
      .vacancy-list {
        font-size: 0;
      }
    }
  }
  @include MQ(EXM) {
    .section-history {
      padding-top: 58px;
      @include clearfix();
    }
  }
  @include MQ(EXL) {
    .section-history {
      padding-top: 80px;
    }
    .section-vacancy {
      padding-top: 55px;
      .vacancy-list {
        @include minusMargins($containerOuterSpace);
      }
    }
    .section-team {
      .team-list {
        @include minusMargins($containerOuterSpace);
      }
    }
  }
  @include MQ(EXXL) {
    h1 {
      font-size: 72px;
    }
    .section-history {
      padding: {
        bottom: 164px;
      }
    }
    .section-team,
    .section-vacancy {
      padding: {
        bottom: 98px;
      }
    }
    .section-vacancy {
      padding-top: 98px;
    }
    .container {
      max-width: 1060px;
      padding: {
        top: 0;
        bottom: 0;
      }
    }
  }
}