 .covervid-wrapper {
   background: {
     position: 0 !important;
   }
   min-height: 100%;
 }
 .covervid-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
 }
 .default-article_video {
   .image-wrap {
     position: relative;
   }
 }
 @include MQ(L) {
   .default-article {
    &.default-article_video {
      .image-wrap {
        position: absolute;
      }
    }   
   }
 }
