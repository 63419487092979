@mixin size($width,$height) {
  width: $width;
  height: $height; }
@mixin square($width) {
  width: $width;
  height: $width; }
.clearfix,.container {
  &:after,&:before {
    content: "";
    display: table; }
  &:after {
    clear: both; } }
@mixin justify {
  font-size: 0;
  text-align: justify;
  text-justify: newspaper;
  li {
    display: inline-block;
    vertical-align: middle; }
  &:after {
    height: 1px;
    margin-top: -1px;
    content: "";
    display: inline-block;
    width: 100%; } }
@mixin vertical {
  font-size: 0;
  text-align: center;
  .help {
    display: inline-block;
    vertical-align: middle; }
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    @include size(.1%,100%);
    margin-left: -.1%; } }
@mixin placeholder($amount1, $amount2) {
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: $amount1; }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: $amount1; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: $amount1; }
  input::placeholder,
  textarea::placeholder {
    color: $amount1; }
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    color: $amount2; }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    color: $amount2; }
  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder {
    color: $amount2; }
  input:focus::placeholder,
  textarea:focus::placeholder {
    color: $amount2; } }
@mixin icomoon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
