.news-inner {
    h1 {
        padding-bottom: 40px;
    }
    .article-tab {
        margin-bottom: 25px;
    }
    .article-credentials-list {
        text-align: center;
        padding-bottom: 15px;
        font-size: 0;
        max-width: 300px;
        margin: 0 auto;
        .time-prop,
        .watch-prop {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            width: 50%;
            padding: 7px;
        }
        .time-prop {
            background: #ebebeb;
        }
        .icon-eye {
            color: $green;
            padding-right: 5px;
        }
    }
    .image-full-width {
        min-height: 350px;
        margin-bottom: 25px;
        background: {
            size: cover;
            position: center center;
            repeat: no-repeat;
        }
        transition: all .2s linear;

        // @meadia (min-width: 440px){
        //     min-height: 210px;
        // }
        // @include MQ(S){
        //     min-height: 220px;
        // }
        // @media (min-width: 500px){
        //     min-height: 240px;
        // }
        // @media (min-width: 640px){
        //     min-height: 340px;
        // }
        // @include MQ(M){
        //     min-height: 350px;
        // }
        @include MQ(L){
            min-height: 310px;
            cursor: pointer;
        }



    }
    .article-footer-heading {
        font-size: 24px;
        font-family: $bold_font;
        padding-bottom: 25px;
    }
    .another-articles-list {
        .link{
            display: inline;
        }
        text-align: left;
        width: 580px;
        max-width: 100%;
        font-size: 0;
        .which-article {
            padding: {
                bottom: 30px;
            }
            span {
                font-size: 18px;
                font-family: $bold-font;
                color: $colorTextGray;
            }
        }
        .which-article:last-child {
            padding-bottom: 0;
        }
    }
}

.news-inner {
    @include MQ(EXM) {
        .article-credentials-list {
            padding-bottom: 30px;
        }
        .image-full-width {
            margin-bottom: 55px;
            // min-height: 310px;
        }
        .gallery-component {
            // .chief-img-cover {
            //     min-height: 310px;
            // }
        }
        .another-articles-list {
            margin: 0 auto;
            font-size: 0;
            width: 1060px;
            white-space: nowrap;
            overflow: auto;
            @include scrollOut1();
            .which-article {
                border: 7px solid transparent;
                transition: $transition_options;
                width: 33%;
                display: inline-block;
                vertical-align: middle;
                white-space: normal;
                padding-bottom: 0;
                a {
                    display: block;
                    padding: {
                        top: 80px;
                        bottom: 80px;
                        left: 50px;
                        right: 50px;
                    }
                    text-align: center;
                }
                
                &:hover {
                    border: 7px solid $green;
                    span {
                        color: #000000;
                    }
                }
                span{
                    font-size: 24px;
                }
            }
            .which-article-inner{
                text-align: left;
            }
        }
    }
}