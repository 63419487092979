.reviews {
	.container {
		max-width: 925px;
		padding-top: 40px; }
	.h3 {
		margin-bottom: 35px;
		text-align: center; }
	&_row {
		li {
			width: 100%;
			margin-bottom: 35px;
			&:not(:last-child) {
				padding-bottom: 35px;
				border-bottom: 1px solid #e5e5e5; } } }
	.btn-wrap {
		padding: 10px 0 40px;
		text-align: center; } }

@include MQ(M) {
	.reviews {
		.container {
			padding-top: 50px; }
		.h3 {
			margin-bottom: 60px; }
		.btn-wrap {
			padding: 40px 0 80px; }
		&_row {
			margin: 0 -30px;
			font-size: 0;
			li {
				display: inline-block;
				vertical-align: top;
				width: 50%;
				margin-bottom: 60px;
				padding: 0 30px 30px;
				&:not(:last-child) {
					border-bottom: 1px solid transparent; }
				&:nth-last-of-type(-n+2) {
					padding-bottom: 0; } } }
		.btn-wrap {
			.j-btn {
				height: 100px;
				border: 5px solid transparent;
				border-radius: 0;
				width: 100%;
				.help {
					position: relative;
					font-size: 2.4rem;
					&:after {
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						height: 1px;
						width: 100%;
						background: $green;
						opacity: .3;
						transition: all .3s ease; } }
				&:hover {
					background: transparent;
					border: 5px solid $green;
					.help {
						color: #000;
						&:after {
							opacity: 1; } } } } } } }
@include MQ(L) {
	.reviews {
		&_row {
			margin: 0 -70px;
			li {
				padding: 0 70px; } } } }
