.vacancy-list-item {
    &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        padding-bottom: 30px;
    }
    &:not(:first-child) {
        padding-top: 30px;
    }
    padding: {
        left: $containerOuterSpace_mobile;
        right: $containerOuterSpace_mobile;
    }
    .vacancy-title {
        font-family: $bold-font;
        font-weight: 700;
    }
    .vacancy-desc,
    .vacancy-title {
        padding-bottom: 10px;
        display: block;
        font-size: 18px;
    }
    .vacancy-desc{
        line-height: 1.333;
    }
    .vacancy-link {
      .link{
            font-size: 16px;
            color: $green;
            display: inline;
      }
        
    }
}


.vacancy-list-item {
    @include MQ(SM) {
        &:not(:first-child) {
            padding-top: 0;
        }
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-size: 18px;
        &:nth-child(2) {
            padding-top: 0;
        }
        &:not(:last-child) {
            border-bottom: none;
            padding-bottom: 40px;
        }
        &:nth-last-child(-2n+2) {
            padding-bottom: 0;
        }
    }
    @include MQ(EXL) {
        padding: {
            left: $containerOuterSpace;
            right: $containerOuterSpace;
        }
    }
    @include MQ(EXL) {
        font-size: 0;
        cursor: pointer;
        width: 100%;
        padding: {
            left: 0;
            right: 0;
        }
        .vacancy-title,
        .vacancy-link,
        .vacancy-desc {
            display: inline-block;
            vertical-align: top;
            padding: {
                left: 20px;
                right: 20px;
            }
        }
        .vacancy-desc{
            line-height: 1.375;
            padding: {
                left: 48px;
                right: 40px;
            }
        }
        .vacancy-link {
            opacity: 0;
            visibility: hidden;
            transition: all .3 linear;
            .link {
                @include btn();
                &:after {
                    content: none;
                }
                &:hover {
                    background-color: $green;
                    span {
                        color: #ffffff;
                    }
                }
                span {
                    @include vertical();
                    font-size: 16px;
                    color: #000000;
                }
                
                    background-image: none;
                
            }
        }
        .vacancy-title {
            padding-left: 40px;
        }
        p.vacancy-desc {
            width: 50%;
            padding-bottom: 0;
        }
        .vacancy-title,
        .vacancy-link {
            width: 25%;
        }
        &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            padding-bottom: 30px;
        }
        &:not(:first-child) {
            padding-top: 30px;
        }
        &:hover {
            .vacancy-link {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}